import React from 'react';
import { Col, Row, Typography } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';

import { Metadata } from '../components';
import aboutImage from "../images/about.jpg"

import StandardLayout from '../layouts/standard';

const IndexPage = () => {

	const { markdownRemark } = useStaticQuery(
		graphql`
			query {
        		markdownRemark(fileAbsolutePath: {regex: "/content\/about/"}) {
					frontmatter {
			  			title
					}
					html
					htmlAst
		  		}
      		}
    	`
	);

	return (
		<StandardLayout>
			<Metadata title={ markdownRemark.frontmatter.title } />
			<Row>
      			<Col span={24}>
				  <Typography.Title level={2}>{markdownRemark.frontmatter.title}</Typography.Title>
				</Col>
    		</Row>
			<Row gutter={ { xs: 8, sm: 16, md: 24, lg: 32 } } className="split-with-image">
				<Col xs={ 24 } lg={ 12 } className="text-col">
					<div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
				</Col>
				<Col xs={ 24 } lg={ 12 } className="image-col">
					<img src={ aboutImage } alt="Matt Dehnel conducting with black background and band seated in the foreground." />
				</Col>
			</Row>
		</StandardLayout>
	)
};

export default IndexPage;
